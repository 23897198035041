//const url = "http://localhost:5000";
const headers = { "Content-Type": "application/json" };

async function createUser(username, password) {
  const response = await fetch("/api/createUser", {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ username, password }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message);
  }

  const result = await response.json();
  return result;
}

async function login(username, password) {
  try {
    const response = await fetch("/api/login", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Login failed");
    }

    const { token, permission } = await response.json();
    localStorage.setItem("token", token);

    return { success: true, permission };
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
}

module.exports = { createUser, login };

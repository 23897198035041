<script setup>
import { ref, computed, onMounted, watch, onUnmounted } from "vue";
import { Search } from "@element-plus/icons-vue";
import PaginationComp from "@/components/PaginationComp.vue";
import {
  getProData,
  getUrbanArea,
  updateType2,
  lastUpdateDate,
  getKeyword,
  deleteKeywords,
  insertKeywords,
} from "@/api/data";
import { getRecord } from "@/api/record";
import { ElMessage, ElTable, ElTableColumn } from "element-plus";

const inputSearch = ref("");
const currentPage = ref(1);
const isLoading = ref(false);
const pageSize = ref(10);
const totalItems = ref(0);
const tableData = ref([]);
const filterSubsidy = ref("");
const filterAssit = ref("");
const urbanArea = ref([]);
const urbans = ref([]);
const areas = ref([]);
const filterUrban = ref("");
const filterArea = ref("");
const random = ref(false);
const selectedDate = ref("");
const numBegin = ref(0);
const numEnd = ref(200);
const excludedUrbanValues = ref(["其它区域", "全省", "省级"]);
const filterVer = ref("");
const verFocus = ref(null);
const filterSix = ref("");
const filterEight = ref("");
const updateDate = ref("");
const dialogVisible = ref(false);
const radio = ref("2");
const keywordsList = ref([]);
const keywords = ref([]);
const list = ref([]);
const options = ref([]);
const value = ref([]);
const loading = ref(false);
const newTask = ref("");
const tasks = ref([]);
let timer = ref(null);
const dialogVisible1 = ref(false);
const gridData = ref([]);
const tableColumns = ref([{ label: "日期", prop: "date" }]);
const filterManual = ref(false);

const fetchDataParams = computed(() => ({
  page: currentPage.value,
  input: inputSearch.value,
  filterSubsidy: filterSubsidy.value,
  filterAssit: filterAssit.value,
  filterUrban: filterUrban.value,
  filterArea: filterArea.value,
  random: random.value,
  selectedDate: selectedDate.value,
  numBegin: numBegin.value,
  numEnd: numEnd.value,
  filterVer: filterVer.value,
  filterSix: filterSix.value,
  filterEight: filterEight.value,
  filterManual: filterManual.value,
}));

const addTask = () => {
  if (newTask.value.trim()) {
    tasks.value.push(newTask.value.trim());
    newTask.value = "";
  }
};

const removeTask = (index) => {
  tasks.value.splice(index, 1);
};
//获取更新日期
const getUpdateDate = async () => {
  const res = await lastUpdateDate();
  updateDate.value = res.message;
};

//区间选择
function debounce(fn, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}

const handleChangeWordCount = (type, val) => {
  if (type === 0) numBegin.value = val;
  if (type === 1) numEnd.value = val;
  fetchTableData();
};

const debouncedHandleChangeWordCount = debounce(handleChangeWordCount, 500);
//获取数据
const fetchTableData = async () => {
  isLoading.value = true;
  try {
    const response = await getProData(fetchDataParams.value);
    tableData.value = response.data.data;
    totalItems.value = response.data.totalCount;
  } catch (error) {
    console.error("Error:", error);
  } finally {
    isLoading.value = false;
  }
};

//获取市区
const fetchUrbanArea = async () => {
  try {
    const response = await getUrbanArea();
    urbanArea.value = response.data;
    urbans.value = Object.keys(urbanArea.value);
  } catch (error) {
    console.error("Error:", error);
  }
};

//更新关键词表
const updateKeywordsList = () => {
  if (radio.value === "1") {
    keywords.value = keywordsList.value.content;
  } else {
    keywords.value = keywordsList.value.title;
  }

  list.value = keywords.value.map((item) => {
    return { value: `${item.id}`, label: `${item.keyword}` };
  });
};
//获取关键词列表
const getKeywords = async () => {
  try {
    const response = await getKeyword();
    keywordsList.value = response.data.data;
    updateKeywordsList();
  } catch (error) {
    console.error("Error:", error);
  }
};

// 删除关键词
const handleDelete = async () => {
  if (value.value.length > 0) {
    const ids = value.value;
    deleteKeywords(ids)
      .then(() => {
        ElMessage.success("删除成功！");
        getKeywords();
        value.value = [];
      })
      .catch((error) => {
        console.log("Update failed:", error);
        ElMessage.error("删除失败！");
      });
  }
};

//添加关键词
const handleInsert = async () => {
  if (tasks.value.length > 0) {
    const type = radio.value;
    const keywords = tasks.value;
    insertKeywords(type, keywords)
      .then((res) => {
        const { existingKeywords, insertedKeywords } = res.data;
        const keywordType = radio.value === "1" ? "正向关键词" : "反向关键词";
        let message = `${keywordType}：`;

        if (existingKeywords && existingKeywords.length > 0) {
          const existingStr = existingKeywords.join("，");
          message += `${existingStr} 已存在`;
        }

        if (insertedKeywords && insertedKeywords.length > 0) {
          const insertedStr = insertedKeywords.join("，");
          message += `，${insertedStr} 添加成功`;
        }

        ElMessage.success(message);
        getKeywords();
        tasks.value = [];
      })
      .catch((error) => {
        console.log("Update failed:", error);
        ElMessage.error("添加失败！");
      });
  }
};
//select框过滤
const remoteMethod = (query) => {
  if (query) {
    loading.value = true;
    setTimeout(() => {
      loading.value = false;
      options.value = list.value.filter((item) => {
        return item.label.toLowerCase().includes(query.toLowerCase());
      });
    }, 200);
  } else {
    options.value = [];
  }
};

const handlePageChange = ({ currentPage: newPage, pageSize: newSize }) => {
  currentPage.value = newPage;
  pageSize.value = newSize;
  random.value = false;
  fetchTableData();
};

const getFilter = (type, val) => {
  random.value = false;
  currentPage.value = 1;
  if (type == 0) filterSubsidy.value = val;
  if (type == 1) {
    filterAssit.value = val;
    if (filterAssit.value == "1") {
      filterSix.value = "";
      filterEight.value = "";
    }
  }
  if (type == 2) inputSearch.value = val;
  if (type == 3) {
    filterUrban.value = val;
    areas.value = urbanArea.value[filterUrban.value];
    filterArea.value = "";
  }
  if (type == 4) filterArea.value = val;
  if (type == 5) random.value = val;
  if (type == 6) selectedDate.value = val;
  if (type == 7) filterVer.value = val;
  if (type == 8) filterSix.value = val;
  if (type == 9) filterEight.value = val;
  fetchTableData();
};

//单个判断
const verificatChange = (id, val) => {
  if (verFocus.value) {
    verFocus.value.focus();
  }
  updateType2(id, val)
    .then(() => {
      ElMessage.success("核实成功！");
      fetchTableData();
    })
    .catch((error) => {
      console.log("Update failed:", error);
      ElMessage.error("核实失败！");
    });
};

//处理ptimeint
const convertUnixTimestampToDate = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000);
  date.setHours(date.getHours() + 8);
  const year = date.getUTCFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

//返回上一页
const goBack = () => {
  window.history.back();
};

//定时检查刷新
const checkTimeAndRefresh = () => {
  const currentTime = new Date();
  const isOneThirtyAM =
    currentTime.getHours() === 1 && currentTime.getMinutes() === 30;

  if (isOneThirtyAM) {
    window.location.reload();
  }
};

//前一日时间戳
const getPreviousDayMidnightTimestamp = () => {
  const now = new Date();
  const timestamp = new Date(now.getTime());

  timestamp.setDate(timestamp.getDate() - 1);
  timestamp.setHours(0, 0, 0, 0);

  return Math.floor(timestamp.getTime() / 1000);
};

//补7
function processOb2(ob_2) {
  const daysRange = [1, 2, 3, 4, 5, 6, 7];

  for (const timestamp in ob_2) {
    const dayDiffMap = {};

    ob_2[timestamp].forEach((item) => {
      dayDiffMap[item.day_diff] = item.record_count;
    });

    daysRange.forEach((day) => {
      if (!(day in dayDiffMap)) {
        dayDiffMap[day] = 0;
      }
    });

    ob_2[timestamp] = dayDiffMap;
  }

  return ob_2;
}

//实际：总数 - 补/日
function processData(ob_1, ob_2) {
  const result = {};

  for (const timestamp in ob_1) {
    const totalRecordCount = ob_1[timestamp];
    const dayDiffs = ob_2[timestamp];

    const totalOb2Sum = Object.values(dayDiffs).reduce(
      (acc, val) => acc + val,
      0
    );

    const newOb1Value = totalRecordCount - totalOb2Sum;

    const newArray = [];
    for (let i = 1; i < 7; i++) {
      newArray.push(dayDiffs[i] !== undefined ? dayDiffs[i] : 0);
    }

    result[timestamp] = [newOb1Value, ...newArray];
  }

  return result;
}

// 格式化日期为 MM/DD
const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month < 10 ? "0" + month : month}/${day < 10 ? "0" + day : day}`;
};

const processTableData = (rawData) => {
  const dataArray = Object.entries(rawData).map(([timestamp, values]) => {
    const formattedDate = formatDate(Number(timestamp)); // 格式化日期
    return {
      date: formattedDate,
      column_1: values[0],
      column_2: values[1],
      column_3: values[2],
      column_4: values[3],
      column_5: values[4],
      column_6: values[5],
      column_7: values[6],
    };
  });

  const timestamps = Object.keys(rawData);
  tableColumns.value = [
    { label: "日期", prop: "date" },
    ...timestamps.map((timestamp, index) => ({
      label: formatDate(Number(timestamp)),
      prop: `column_${index + 1}`,
    })),
  ];
  gridData.value = dataArray;
};

//7日数据
const getPreviousSevData = async () => {
  dialogVisible1.value = false;
  try {
    const datePara = getPreviousDayMidnightTimestamp();
    const response = await getRecord(datePara);
    const data = response.data[0].ob_1;
    const ob_1 = Object.keys(data).reduce((acc, key) => {
      const newKey = key.replace("t_", "");
      acc[newKey] = parseInt(data[key], 10);
      return acc;
    }, {});
    const ob_2 = processOb2(response.data[0].ob_2);
    const processedData = processData(ob_1, ob_2);
    const output = {};

    Object.entries(processedData).forEach(([key, value], index) => {
      if (index === 0) {
        output[key] = value;
      } else {
        const elementsToMove = value.splice(-index);
        output[key] = [...elementsToMove, ...value];
      }
    });

    processTableData(output);
  } catch (error) {
    console.error("Error:", error);
  } finally {
    dialogVisible1.value = true;
  }
};

watch(radio, () => {
  updateKeywordsList();
});

onMounted(async () => {
  await getKeywords();
  await getUpdateDate();
  await fetchTableData();
  await fetchUrbanArea();
  debouncedHandleChangeWordCount();
  timer.value = setInterval(checkTimeAndRefresh, 60 * 60 * 1000);
});

onUnmounted(() => {
  clearInterval(timer.value);
});
</script>
<template>
  <div style="margin: 10px 60px">
    <div class="mrg-bot">
      <el-button plain @click="goBack">返回</el-button
      ><el-button type="primary" @click="fetchTableData">重置</el-button
      >&nbsp;&nbsp;{{ updateDate }}
    </div>
    <div class="mrg-bot">
      <el-input
        ref="verFocus"
        v-model="inputSearch"
        style="max-width: 643px"
        placeholder="请输入内容..."
        @keyup.enter="getFilter(2, inputSearch)"
      >
        <template #append
          ><el-button :icon="Search" @click="getFilter(2, inputSearch)"
        /></template>
      </el-input>
    </div>
    <div class="slider-container">
      <span class="label">区间个数：</span>
      <el-input-number
        v-model="numBegin"
        :min="1"
        :max="200"
        size="small"
        controls-position="right"
        @change="debouncedHandleChangeWordCount(0, numBegin)"
      />
      &nbsp;至&nbsp;
      <el-input-number
        v-model="numEnd"
        :min="1"
        :max="200"
        size="small"
        controls-position="right"
        @change="debouncedHandleChangeWordCount(1, numEnd)"
      />
    </div>
    <div style="display: flex; align-items: center">
      <div class="page-button">
        <div class="pagination-container">
          <PaginationComp
            :total-items="totalItems"
            :initial-page-size="pageSize"
            v-model="currentPage"
            :disabled="isLoading"
            @page-change="handlePageChange"
          />
        </div>
        <div>
          <el-button type="primary" round @click="getFilter(5, true)"
            >随机获取</el-button
          >
        </div>
      </div>
      <div style="margin-left: 20px">
        <el-button plain @click="dialogVisible = true" type="primary">
          关键词操作
        </el-button>

        <el-dialog
          v-model="dialogVisible"
          title="关键字操作"
          width="500"
          :close-on-click-modal="false"
        >
          <div class="my-2 ml-4">
            <el-radio-group v-model="radio">
              <el-radio value="1">正向</el-radio>
              <el-radio value="2">反向</el-radio>
            </el-radio-group>
          </div>
          <div style="margin-bottom: 10px">
            <el-select
              v-model="value"
              multiple
              filterable
              remote
              reserve-keyword
              placeholder="Please enter a keyword"
              remote-show-suffix
              :remote-method="remoteMethod"
              :loading="loading"
              style="width: 240px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-button type="primary" @click="handleDelete">删除</el-button>
          </div>
          <div>
            <el-input
              v-model="newTask"
              style="width: 240px"
              @keyup.enter="addTask"
              placeholder="Please input new keyword"
            />
            <div class="todo-list" v-if="tasks.length > 0">
              <ul>
                <li v-for="(task, index) in tasks" :key="index">
                  {{ task }}
                  <button @click="removeTask(index)">删除</button>
                </li>
              </ul>
              <div class="submit-btn">
                <el-button type="primary" @click="handleInsert">添加</el-button>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
      <div style="margin-left: 20px">
        <el-button plain @click="getPreviousSevData()" type="primary">
          七日数据
        </el-button>
        <el-dialog
          v-model="dialogVisible1"
          title="七日数据统计"
          width="620"
          :close-on-click-modal="false"
        >
          <div class="horizontal-text">实际发布日期</div>
          <div class="table-container">
            <div class="vertical-text">发布日期</div>
            <el-table :data="gridData" border style="width: 100%">
              <el-table-column
                v-for="(column, index) in tableColumns"
                :key="index"
                :label="column.label"
                :prop="column.prop"
                width="70"
              />
            </el-table>
          </div>
        </el-dialog>
      </div>
      <div style="margin-left: 20px">
        <el-switch v-model="filterManual" @change="fetchTableData" />
      </div>
    </div>

    <div class="table-responsive" v-loading="isLoading">
      <table class="table">
        <colgroup>
          <col width="80" />
          <col width="300" />
          <col width="100" />
          <col width="200" />
          <col width="100" />
          <col width="120" />
          <col width="80" />
          <col width="120" />
          <col width="120" />
          <col width="150" />
          <col width="100" />
          <col width="140" />
          <col width="140" />
          <col width="160" />
        </colgroup>
        <thead>
          <tr>
            <th>编号</th>
            <th>标题</th>
            <th>反向</th>
            <th>正向</th>
            <th></th>
            <th>
              <div class="header-container">
                <div class="header-label">5</div>
                <div class="header-controls">
                  <el-select
                    v-model="filterSubsidy"
                    placeholder="选择"
                    @change="getFilter(0, filterSubsidy)"
                  >
                    <el-option label="全部" value=""></el-option>
                    <el-option label="通过" value="oneUnknown"></el-option>
                    <el-option label="一定是" value="1"></el-option>
                    <el-option label="不确定" value="unknown"></el-option>
                    <el-option label="一定不是" value="0"></el-option>
                  </el-select>
                </div>
              </div>
            </th>
            <th>5.5</th>
            <th>
              <div class="header-container">
                <div class="header-label">6</div>
                <div class="header-controls">
                  <el-select
                    v-model="filterSix"
                    placeholder="选择"
                    @change="getFilter(8, filterSix)"
                  >
                    <el-option label="全部" value=""></el-option>
                    <el-option label="通过" value="1"></el-option>
                    <el-option label="排除" value="0"></el-option>
                  </el-select>
                </div>
              </div>
            </th>
            <th>
              <div class="header-container">
                <div class="header-label">8</div>
                <div class="header-controls">
                  <el-select
                    v-model="filterEight"
                    placeholder="选择"
                    @change="getFilter(9, filterEight)"
                  >
                    <el-option label="全部" value=""></el-option>
                    <el-option label="通过" value="1"></el-option>
                    <el-option label="排除" value="0"></el-option>
                  </el-select>
                </div>
              </div>
            </th>
            <th>
              <div class="header-container">
                <div class="header-label">政策判断</div>
                <div class="header-controls">
                  <el-select
                    v-model="filterAssit"
                    placeholder="选择"
                    @change="getFilter(1, filterAssit)"
                  >
                    <el-option label="全部" value=""></el-option>
                    <el-option label="通过" value="1"></el-option>
                    <el-option label="排除" value="0"></el-option>
                  </el-select>
                </div>
              </div>
            </th>
            <th>判断</th>
            <th>
              <div class="header-container">
                <div class="header-label">市</div>
                <div class="header-controls">
                  <el-select
                    v-model="filterUrban"
                    placeholder="选择"
                    @change="getFilter(3, filterUrban)"
                  >
                    <el-option
                      :key="'all'"
                      label="全省"
                      value="全省"
                    ></el-option>
                    <el-option
                      :key="'proLevel'"
                      label="省级"
                      value="省级"
                    ></el-option>
                    <el-option
                      v-for="(name, index) in urbans"
                      :key="index"
                      :label="name"
                      :value="name"
                    ></el-option>
                    <el-option
                      :key="'other'"
                      label="其它区域"
                      value="其它区域"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </th>
            <th>
              <div class="header-container">
                <div class="header-label">区</div>
                <div class="header-controls">
                  <el-select
                    v-model="filterArea"
                    placeholder="选择"
                    @change="getFilter(4, filterArea)"
                  >
                    <el-option
                      v-if="!excludedUrbanValues.includes(filterUrban)"
                      :key="'all'"
                      label="全市"
                      value="全市"
                    ></el-option>
                    <el-option
                      v-for="(area, index) in areas"
                      :key="index"
                      :label="area.name"
                      :value="area.name"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </th>
            <th>
              <div class="header-container">
                <!-- <div class="header-label">发布时间</div> -->

                <div class="header-controls">
                  <el-date-picker
                    v-model="selectedDate"
                    type="date"
                    placeholder="选择日期"
                    width="260"
                    @change="getFilter(6, selectedDate)"
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <template v-if="tableData.length > 0">
          <tbody v-for="(item, index) in tableData" :key="index">
            <tr>
              <th>
                {{ (currentPage - 1) * pageSize + index + 1 }}
              </th>
              <th class="text-line">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="点击跳转详情"
                  placement="top"
                >
                  <div>
                    <a
                      target="_blank"
                      :href="item.post_url"
                      style="color: #606266; text-decoration: none"
                      >{{ item.title }}</a
                    >
                  </div>
                </el-tooltip>
              </th>
              <th class="text-line">{{ item.keyword_0 }}</th>
              <th class="text-line">{{ item.keyword_1 }}</th>
              <th>{{ item.wordResult }}</th>
              <th>{{ item.type_1 }}</th>
              <th>{{ item.type_0 }}</th>
              <th>{{ item.type_5 }}</th>
              <th>{{ item.type_3 }}</th>
              <th class="text-line">
                {{ item.type_2
                }}<el-tag
                  type="danger"
                  v-if="item.check_repeat !== null"
                  style="float: right"
                  >{{ item.check_repeat }}</el-tag
                >
              </th>
              <th>
                <el-radio-group
                  v-model="item.type_2"
                  @change="verificatChange(item.id, item.type_2)"
                  ><el-radio :label="'是'" style="margin-bottom: 10px"
                    >是政策</el-radio
                  >

                  <el-radio :label="'否'">非政策</el-radio>
                </el-radio-group>
              </th>
              <th>{{ item.urban }}</th>
              <th class="text-line">{{ item.area }}</th>
              <th>{{ convertUnixTimestampToDate(item.ptimeint) }}</th>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
            <tr>
              <td colspan="14">无数据...</td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>
<style scoped>
.table-container {
  display: flex;
  align-items: center;
}
.el-table .el-table__cell,
.el-table .cell {
  text-align: center;
}

.vertical-text {
  writing-mode: vertical-rl;
  margin-right: 10px;
  text-align: center;
  letter-spacing: 5px;
  font-size: 16px;
}
.horizontal-text {
  letter-spacing: 5px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.pagination-container {
  display: flex;
  justify-content: left;
  margin: 10px 0;
}
.mrg-bot {
  margin-bottom: 20px;
}
.table-responsive {
  min-width: 2000px;
  overflow: auto;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
}
td,
th {
  border: 1px solid #ebeef5;
  text-align: left;
  padding: 10px;
  vertical-align: middle;
  color: #909399;
}
/* .text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 25px;
} */
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text-line {
  line-height: 25px;
}
.header-label {
  flex: 1;
}

.header-controls {
  width: 90px;
}
.page-button {
  display: flex;
  justify-content: space-between;
  width: 643px;
  align-items: center;
}
::v-deep .el-date-editor {
  width: 145px !important;
}
.slider-container {
  display: flex;
  align-items: center;
}
.todo-list {
  max-width: 240px;
  border: 1px solid #ccc;
}

ul {
  list-style-type: none;
  padding: 5px;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.submit-btn {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}
</style>

<script setup>
import { ref, onMounted, computed } from "vue";
import { Search } from "@element-plus/icons-vue";
import PaginationComp from "@/components/PaginationComp.vue";
import { filterAllData, getUrbanArea } from "@/api/data";
import { useRoute } from "vue-router";

const route = useRoute();
const area = ref("");
const tableData = ref([]);
const isLoading = ref(false);
const currentPage = ref(1);
const pageSize = ref(10);
const totalItems = ref(0);
const inputSearch = ref("");
const urbanArea = ref([]);
const urbans = ref([]);
const areas = ref([]);
const filterUrban = ref("");
const filterArea = ref("");
const selectDate = ref("");
const filterDate = ref("");

const fetchDataParams = computed(() => ({
  area: area.value,
  page: currentPage.value,
  input: inputSearch.value,
  filterUrban: filterUrban.value,
  filterArea: filterArea.value,
  filterDate: filterDate.value,
}));

//获取数据
const fetchTableData = async () => {
  isLoading.value = true;
  try {
    const response = await filterAllData(fetchDataParams.value);
    tableData.value = response.data.data;
    totalItems.value = response.data.totalCount;
  } catch (error) {
    console.error("Error:", error);
  } finally {
    isLoading.value = false;
  }
};

//获取市区
const fetchUrbanArea = async () => {
  try {
    const response = await getUrbanArea();
    urbanArea.value = response.data;

    urbans.value = Object.keys(urbanArea.value);
  } catch (error) {
    console.error("Error:", error);
  }
};

onMounted(async () => {
  area.value = decodeURIComponent(route.query.area || "");
  await fetchTableData();
  await fetchUrbanArea();
});

const getFilter = (type, val) => {
  if (type === 0) inputSearch.value = val;
  if (type === 1) {
    filterUrban.value = val;
    areas.value = urbanArea.value[filterUrban.value];
    area.value = "";
    filterArea.value = "";
  }
  if (type === 2) filterArea.value = val;

  if (type === 3) {
    selectDate.value = val;
    const dateObj = new Date(val);
    const timestampInSeconds = Math.floor(dateObj.getTime() / 1000);
    filterDate.value = timestampInSeconds;
  }
  currentPage.value = 1;
  fetchTableData();
};

const goBack = () => {
  window.history.back();
};

const resetData = () => {
  inputSearch.value = "";
  filterUrban.value = "";
  areas.value = urbanArea.value[filterUrban.value];
  area.value = "";
  filterArea.value = "";
  currentPage.value = 1;
  fetchTableData();
};

const handlePageChange = ({ currentPage: newPage, pageSize: newSize }) => {
  currentPage.value = newPage;
  pageSize.value = newSize;
  fetchTableData();
};
</script>
<template>
  <div class="main-box">
    <div class="mrg-bot">
      <div>
        <el-button plain @click="goBack">返回</el-button
        ><el-button type="primary" @click="resetData()">重置</el-button>
      </div>
      <div class="block">
        <el-date-picker
          v-model="selectDate"
          type="date"
          placeholder="Pick a day"
          @change="getFilter(3, selectDate)"
        />
      </div>
    </div>

    <el-row>
      <el-col :span="12" class="align-center"
        ><span style="padding-left: 16px">市：</span
        ><el-select
          v-model="filterUrban"
          placeholder="选择"
          @change="getFilter(1, filterUrban)"
        >
          <el-option :key="'all'" label="全省" value="全省"></el-option>
          <el-option
            v-for="(name, index) in urbans"
            :key="index"
            :label="name"
            :value="name"
          ></el-option>
          <el-option
            :key="'other'"
            label="其它区域"
            value="其它区域"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="12" class="align-center">
        <span style="padding-left: 16px">区：</span>
        <el-select
          v-model="filterArea"
          placeholder="选择"
          @change="getFilter(2, filterArea)"
        >
          <el-option
            v-if="filterUrban !== '全省' && filterUrban !== '其它区域'"
            :key="'all'"
            label="全市"
            value="全市"
          ></el-option>
          <el-option
            v-for="(area, index) in areas"
            :key="index"
            :label="area.name"
            :value="area.name"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <div class="align-center" style="margin-top: 10px">
      <span class="no-wrap">搜索：</span>
      <el-input
        v-model="inputSearch"
        placeholder="请输入..."
        @keyup.enter="getFilter(0, inputSearch)"
      >
        <template #append>
          <el-button :icon="Search" @click="getFilter(0, inputSearch)" />
        </template>
      </el-input>
    </div>
    <div class="pagination-container">
      <PaginationComp
        :total-items="totalItems"
        :initial-page-size="pageSize"
        v-model="currentPage"
        :disabled="isLoading"
        @page-change="handlePageChange"
      />
    </div>
    <div class="table-responsive" v-loading="isLoading">
      <table class="table">
        <colgroup>
          <col width="80" />
          <col width="560" />
          <col width="100" />
          <col width="120" />
        </colgroup>
        <thead>
          <tr>
            <th>编号</th>
            <th>标题</th>
            <th>市</th>
            <th>区</th>
          </tr>
        </thead>
        <template v-if="tableData.length > 0">
          <tbody v-for="(item, index) in tableData" :key="index">
            <tr>
              <th style="text-align: center">
                {{ (currentPage - 1) * pageSize + index + 1 }}
              </th>
              <th>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="点击跳转详情"
                  placement="top"
                >
                  <div class="text-line">
                    <a
                      target="_blank"
                      :href="item.post_url"
                      style="color: #606266; text-decoration: none"
                      >{{ item.title }}</a
                    >
                  </div>
                </el-tooltip>
              </th>
              <th>{{ item.urban }}</th>
              <th class="text-line">{{ item.area }}</th>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
            <tr>
              <td colspan="4">无数据...</td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<style scoped>
.main-box {
  color: #909399;
  max-width: 860px;
  min-width: 556px;
  margin: 30px auto;
}
.table-responsive {
  overflow: auto;
}
table {
  table-layout: fixed;
  border-collapse: collapse;
}
td,
th {
  border: 1px solid #ebeef5;
  text-align: left;
  padding: 10px;
  vertical-align: middle;
  color: #909399;
}
.pagination-container {
  display: flex;
  justify-content: left;
  margin: 10px 0;
}
.text-line {
  line-height: 25px;
}
.align-center {
  display: flex;
  align-items: center;
}
.no-wrap {
  white-space: nowrap;
}
.mrg-bot {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ebeef5;
}
</style>

<script setup>
import { ref, onMounted, computed } from "vue";
import { Search } from "@element-plus/icons-vue";
import PaginationComp from "@/components/PaginationComp.vue";
import { filterData } from "@/api/data";
import { useRoute } from "vue-router";

const route = useRoute();
const area = ref("");
const tableData = ref([]);
const isLoading = ref(false);
const currentPage = ref(1);
const pageSize = ref(10);
const totalItems = ref(0);
const dataType = ref("area");
const inputSearch = ref("");

const options = [
  {
    value: "urban",
    label: "市级表",
  },
  {
    value: "area",
    label: "区域表",
  },
];

const fetchDataParams = computed(() => ({
  area: area.value,
  page: currentPage.value,
  dataType: dataType.value,
  input: inputSearch.value,
}));

//获取数据
const fetchTableData = async () => {
  isLoading.value = true;
  try {
    const response = await filterData(fetchDataParams.value);
    tableData.value = response.data.data;
    totalItems.value = response.data.totalCount;
  } catch (error) {
    console.error("Error:", error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  area.value = decodeURIComponent(route.query.area || "");
  fetchTableData();
});

const handleChangeFilter = (type, val) => {
  if (!val) return;
  if (type === 0) dataType.value = val;
  if (type === 1) inputSearch.value = val;
  currentPage.value = 1;
  fetchTableData();
};

const handlePageChange = ({ currentPage: newPage, pageSize: newSize }) => {
  currentPage.value = newPage;
  pageSize.value = newSize;
  fetchTableData();
};
</script>
<template>
  <div class="main-box">
    <el-row class="align-center">
      <el-col :span="12"
        ><span>区域：{{ area }}</span>
      </el-col>
      <el-col :span="12" class="align-center">
        <span class="no-wrap">数据：</span>
        <el-select
          v-model="dataType"
          placeholder="请选择表"
          @change="() => handleChangeFilter(0, dataType)"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
    </el-row>
    <div class="align-center" style="margin-top: 10px">
      <span class="no-wrap">搜索：</span>
      <el-input
        v-model="inputSearch"
        placeholder="请输入..."
        @keyup.enter="handleChangeFilter(1, inputSearch)"
      >
        <template #append>
          <el-button
            :icon="Search"
            @click="handleChangeFilter(1, inputSearch)"
          />
        </template>
      </el-input>
    </div>
    <div class="pagination-container">
      <PaginationComp
        :total-items="totalItems"
        :initial-page-size="pageSize"
        v-model="currentPage"
        :disabled="isLoading"
        @page-change="handlePageChange"
      />
    </div>
    <div class="table-responsive" v-loading="isLoading">
      <table class="table">
        <colgroup>
          <col width="80" />
          <col width="300" />
          <col width="100" />
        </colgroup>
        <thead>
          <tr>
            <th>编号</th>
            <th>标题</th>
            <th>区</th>
          </tr>
        </thead>
        <template v-if="tableData.length > 0">
          <tbody v-for="(item, index) in tableData" :key="index">
            <tr>
              <th>
                {{ (currentPage - 1) * pageSize + index + 1 }}
              </th>
              <th>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="点击跳转详情"
                  placement="top"
                >
                  <div class="text-ellipsis">
                    <a
                      target="_blank"
                      :href="item.post_url"
                      style="color: #606266; text-decoration: none"
                      >{{ item.title }}</a
                    >
                  </div>
                </el-tooltip>
              </th>
              <th>{{ item.area }}</th>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
            <tr>
              <td colspan="4">无数据...</td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<style scoped>
.main-box {
  color: #909399;
  margin: 10px;
  max-width: 480px;
}
.table-responsive {
  overflow: auto;
}
table {
  table-layout: fixed;
  border-collapse: collapse;
}
td,
th {
  border: 1px solid #ebeef5;
  text-align: left;
  padding: 10px;
  vertical-align: middle;
  color: #909399;
}
.pagination-container {
  display: flex;
  justify-content: left;
  margin: 10px 0;
}
.text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 20px;
}
.align-center {
  display: flex;
  align-items: center;
}
.no-wrap {
  white-space: nowrap;
}
</style>

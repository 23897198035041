import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/home/homePage.vue"; // 确保后缀名为 .vue
import Login from "../views/login";
import UrbanArea from "../views/urbanArea/urbanArea.vue";
import CityPage from "@/views/cityPage/cityPage.vue";
import proPage from "@/views/provincePage/proPage.vue";
import PcPage from "@/views/pcPage/pcPage.vue";

const routes = [
  {
    path: "/homepage",
    name: "HomePage",
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/pcpage",
    name: "PcPage",
    component: PcPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/citypage",
    name: "CityPage",
    component: CityPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/propage",
    name: "proPage",
    component: proPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/urbanarea/",
    name: "UrbanArea",
    component: UrbanArea,
    props: (route) => ({ area: route.query.area || "未知区域" }),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/login",
  },
];

const router = createRouter({
  history: createWebHistory(), // 使用 HTML5 历史模式
  routes,
});

// 前置守卫
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token");

  // 对需要登录的页面进行处理
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

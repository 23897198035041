<template>
  <div>
    <div class="login">
      <h2 class="title">{{ showRegisterForm ? "REGISTER" : "LOGIN" }}</h2>
      <form v-if="!showRegisterForm" @submit.prevent="loginUser">
        <div>
          <label for="username">Username:</label>
          <input type="text" v-model="username" required />
        </div>
        <div>
          <label for="password">Password:</label>
          <input type="password" v-model="password" required />
        </div>

        <button type="submit">Login</button>
        <!-- <span @click="toggleForm('register')" class="tip_text">注册</span> -->
      </form>
      <form v-if="showRegisterForm" @submit.prevent="register">
        <div>
          <label for="newUsername">New Username:</label>
          <input type="text" v-model="newUsername" required />
        </div>
        <div>
          <label for="newPassword">New Password:</label>
          <input type="password" v-model="newPassword" required />
        </div>
        <button type="submit">Register</button>
        <span class="tip_text" @click="toggleForm('login')">返回</span>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { createUser, login } from "@/api/user";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "LoginForm",
  setup() {
    const username = ref("");
    const password = ref("");
    const showRegisterForm = ref(false);
    const newUsername = ref("");
    const newPassword = ref("");
    const router = useRouter();

    const toggleForm = (formType) => {
      showRegisterForm.value = formType === "register";
      username.value = "";
      password.value = "";
      newUsername.value = "";
      newPassword.value = "";
    };

    const register = async () => {
      try {
        const result = await createUser(newUsername.value, newPassword.value);
        if (result.message === "User created successfully") {
          // Use a notification library like Element Plus
          ElMessage.success("注册成功！");
          router.push("/homepage");
        }
      } catch (error) {
        console.error("Error creating user:", error.message);
      }
    };

    const loginUser = async () => {
      try {
        const result = await login(username.value, password.value);
        console.log("User created:", result);
        if (result.success) {
          if (result.permission == "0") {
            router.push("/propage");
          } else if (result.permission == "1") {
            router.push("/pcpage");
          }
        }
      } catch (error) {
        if (error.message === "Invalid credentials") {
          ElMessage.error("密码不正确！");
        } else if (error.message === "User not found") {
          ElMessage.error("用户名不存在，请先注册！");
        } else {
          console.error("Error login user:", error.message);
          ElMessage.error("登录失败，请重试！");
        }
      }
    };

    return {
      username,
      password,
      showRegisterForm,
      newUsername,
      newPassword,
      toggleForm,
      register,
      loginUser,
    };
  },
});
</script>

<style scoped>
.tip_text {
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}
.title {
  text-align: center;
  font-weight: bold;
  color: #333333;
  font-size: 20px;
  margin-bottom: 10px;
}
.login {
  max-width: 300px;
  margin: 0 auto;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 100px;
}
.login div {
  margin-bottom: 1em;
}
.login label {
  margin-bottom: 0.5em;
  color: #333333;
  display: block;
}
.login input {
  border: 1px solid #cccccc;
  padding: 0.5em;
  font-size: 1em;
  width: 100%;
  box-sizing: border-box;
}
.login button {
  padding: 0.7em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.login button:hover {
  background-color: #0056b3;
}
</style>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter(); // 使用 useRouter 获取路由实例

const goToPage = (type) => {
  if (type == 0) router.push("/propage");
  if (type == 1) router.push("/citypage");
  if (type == 2) router.push("/pcpage");
};
</script>
<template>
  <div style="margin: 60px">
    <el-button type="primary" plain @click="goToPage(0)">省数据表</el-button>
    <el-button type="primary" plain @click="goToPage(1)">市数据表</el-button>
    <el-button type="primary" plain @click="goToPage(2)">网页端</el-button>
  </div>
</template>

<template>
  <div class="pagination">
    <el-button
      @click="prevPage"
      :disabled="currentPage === 1"
      circle
      :icon="CaretLeft"
      type="primary"
    ></el-button>
    <span>第 {{ currentPage }} 页</span>
    <el-button
      @click="nextPage"
      :disabled="currentPage === totalPages"
      circle
      :icon="CaretRight"
      type="primary"
    ></el-button>
    <span>共 {{ totalItems }} 条</span>
  </div>
</template>
<script>
import { CaretLeft, CaretRight } from "@element-plus/icons-vue";
import { ref, computed, onMounted, onUnmounted, watch } from "vue";

export default {
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    initialPageSize: {
      type: Number,
      default: 10,
    },
  },
  setup(props, { emit }) {
    const currentPage = ref(props.modelValue);
    const pageSize = ref(props.initialPageSize);

    const totalPages = computed(() => {
      return Math.ceil(props.totalItems / pageSize.value);
    });

    watch(
      () => props.modelValue,
      (newValue) => {
        currentPage.value = newValue;
      }
    );

    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        prevPage();
      } else if (event.key === "ArrowRight") {
        event.preventDefault();
        nextPage();
      }
    };

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        emitPageChange();
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
        emitPageChange();
      }
    };

    const emitPageChange = () => {
      emit("update:modelValue", currentPage.value);
      emit("page-change", {
        currentPage: currentPage.value,
        pageSize: pageSize.value,
      });
    };

    onMounted(() => {
      window.addEventListener("keydown", handleKeyDown);
    });

    onUnmounted(() => {
      window.removeEventListener("keydown", handleKeyDown);
    });

    return {
      currentPage,
      totalPages,
      prevPage,
      nextPage,
      CaretLeft,
      CaretRight,
    };
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination button {
  margin: 0 5px;
}
.pagination select {
  margin-left: 10px;
}
.el-button.is-circle {
  padding: 5px;
}
.el-input__inner {
  height: 30px;
}
.el-input__icon,
.el-input__inner {
  line-height: 30px;
}
</style>
